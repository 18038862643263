<template>
  <v-card class="elevation-0">
    <v-card-title>
      Users
      <v-spacer></v-spacer>

        <v-text-field
            v-on:keyup.enter="searchOnEnter"
            v-on:blur="searchOnEnter"
            append-icon="mdi-magnify"
            hint="Username, Email, Full Name (press ENTER to search)"
            label="Search"
        ></v-text-field>

    </v-card-title>
    <v-data-table
        v-model="selectedItems"
        :single-select="!multiSelect"
        :show-select="multiSelect"
        :headers="headers"
        :items="usersFiltered"
        :page.sync="currentPage"
        :items-per-page="pageSize"
        hide-default-footer
        class="elevation-0"
        @page-count="totalTablePages =totalPaginationPages "
    >
      <template v-slot:top>
        <v-row align="center" class="ml-4 mr-4">
          <v-switch
              v-model="multiSelect"
              :label="multiSelect?'Multi Select':'Single Select'"
              x-small
              class="pa-3"
          ></v-switch>
          <v-btn v-if="false"
              fab
              dark
              color="red accent-4"
              x-small
              :disabled="!multiSelect"
              class="mr-2"
              @click="deleteDialogMultiShow"
          >
            <v-icon>mdi-delete</v-icon>
          </v-btn>

          <v-spacer></v-spacer>

          <v-btn
              fab
              dark
              color="green"
              x-small
              @click="showDialogNew"
              :disabled="multiSelect"
          >
            <v-icon>mdi-plus</v-icon>
          </v-btn>
        </v-row>
      </template>

      <template v-slot:[`item.username`]="{ item }">
        <tr class="subtitle-2">
          {{ item.username }}
        </tr>
      </template>

      <template v-slot:[`item.email`]="{ item }">
        <div class="fill-height">
          <div>
            {{ item.email }}
          </div>
<!--          <div>-->
<!--            <v-icon x-small>mdi-phone</v-icon>+{{item.countryCode}} {{item.phone}}-->
<!--          </div>-->
          <div v-if="item.phone>821999">
            <v-btn
                x-small
                class="no-gutters"
                plain :href="`https://api.whatsapp.com/send?phone=+62${item.phone}&text=Hi kak,`" target="_blank"
            >
              <v-icon x-small color="green" >mdi-whatsapp</v-icon>
              <span class="caption">
                  0{{ item.phone }}
                </span>
            </v-btn>
          </div>

        </div>
      </template>

      <template v-slot:[`item.roles`]="{ item }">
        <div class="fill-height caption">
          {{ rolesToArray(item.roles) }}
        </div>
      </template>
      <template v-slot:[`item.fullName`]="{ item }">
        <div class="fill-height">
          <div class="mb-1">
            {{item.fullName}}
          </div>
          <div class="font-weight-bold" v-if="roleAgenGroup(item.roles) && item.salesmanOf>0">

            <v-flex class="d-flex align-baseline blue--text">
              <v-icon small>mdi-cart</v-icon>
              {{ lookupFSalesman(item.salesmanOf).spname }}
            </v-flex>
          </div>
          <div v-else-if="roleAgenGroup(item.roles) && item.salesmanOf ===0">
            <span class="red--text text-decoration-line-through">salesman</span> <v-icon small color="red">mdi-exclamation</v-icon>
          </div>
        </div>
      </template>

      <template v-slot:[`item.fdivisionBean`]="{ item }">
        <div class="fill-height">
          <tr class="caption">
            {{ lookupFDivision(item.fdivisionBean) }}
          </tr>
          <tr>
            <v-chip
                :color="getColorOrganizationLevel(item.organizationLevel)"
                x-small
            >
              {{item.organizationLevel}}
            </v-chip>
            <v-chip
                :color="getColorStatusActive(item.isAccountNonLocked)"
                dark
                x-small
            >
              {{ item.isAccountNonLocked==true?'Aktif': 'Locked' }}
            </v-chip>
          </tr>
        </div>

      </template>

      <template v-slot:[`item.actions`]="{ item }">
        <v-btn
            @click="showDialogEdit(item)"
            icon
            :disabled="multiSelect"
        >
          <v-icon
              small
              color="warning"
          >
            mdi-pencil
          </v-icon>
        </v-btn>

        <v-btn
            @click="deleteDialogShow(item)"
            icon
            :disabled="multiSelect"
        >
          <v-icon
              small
              color="red accent-4"
          >
            mdi-delete
          </v-icon>
        </v-btn>
      </template>

    </v-data-table>
<!--    @page-count="pageCount = $event"-->

    <v-container>
      <v-row justify="end" align="center">
        <v-col
        cols="4"
        md="2"
        sm="2"
        >
          <v-select
              v-model="pageSize"
              :items="pageSizes"
              label="Items per page"
          ></v-select>
        </v-col>
        <v-col
            cols="10"
            md="9"
            sm="8"
            align="right"
        >
          <v-pagination
              v-model="currentPage"
              :length="totalPaginationPages"
              total-visible="8"
              circle
          ></v-pagination>
        </v-col>
      </v-row>
    </v-container>

    <DeleteDialog
        ref="refDeleteConfirmDialog"
        @eventFromDeleteConfirmDialog1="deleteItemConfirmedSingleSelect"
        @eventFromDeleteConfirmDialog2="deleteItemConfirmedMultiSelect"
    ></DeleteDialog>

    <UsersDialog :formMode.sync="formMode"
                  ref="refFormDialog"
                  @eventFromFormDialogNew="saveDataNew"
                  @eventFromFormDialogEdit="saveDataEdit"
    ></UsersDialog>


  </v-card>
</template>

<script>
import UserService from '../../services/user-service';
import DeleteDialog from "../../components/utils/DeleteConfirmDialog";
import UsersDialog from "./UsersDialog";
import FormMode from "../../models/form-mode";
import User from '../../models/user'

import FDivisionService from "../../services/apiservices/f-division-service";
import FSalesmanService from "../../services/apiservices/f-pegawai-service"
import EOrganizationLevel from "../../models/e-organization-level";

export default {
  components: {DeleteDialog, UsersDialog},
  data () {
    return {
      multiSelect: false,
      selectedItems: [],

      currentPage: 1,
      totalTablePages: 1,
      totalPaginationPages: 1,
      pageSize: 10,
      pageSizes: [10, 25, 50, 150, 500],

      search: '',
      headers: [
        {
          text: 'User Name',
          align: 'start',
          sortable: true,
          value: 'username',
          width: '20%'
        },
        { text: 'Email', value: 'email', width: '20%' },
        { text: 'roles', value: 'roles', width: '20%' },
        { text: 'Full Name', value: 'fullName', width: '20%' },
        { text: 'Branch/Distributor', value: 'fdivisionBean',width: '15%' },
        { text: 'Actions', value: 'actions', sortable: false },
      ],

      formMode: '',
      itemSelectedIndex: -1,
      itemSelected: '',
      users:[
        new User(0, 'username', 'email','')
      ],
      itemsFDivision: [
        { id: 0, kode1: '', description: '' },
      ],
      itemsFSalesman: [
        { id: 0, kode1: '', description: '' },
      ],

    }
  },
  watch: {
    currentPage: {
      handler: function (value) {
        if (value) this.fetchUsers()
      }
    },
    pageSize: {
      handler: function (value) {
        const refreshData = (this.currentPage==1)
        this.currentPage =1
        if (refreshData) {
          console.log("Change PageSize " + value)
          this.fetchUsers()
        }
        // console.log("page size = " + value + " >> " + this.totalPages)
      }
    },
  },
  computed: {
    currentUser(){
      return this.$store.state.auth.user;
    },
    usersFiltered(){
      return this.users
    }

  },
  methods: {
    roleAgenGroup(roles){

      // console.log(JSON.stringify(roles))
      const strRoles = JSON.stringify(roles)
      let show = false

      if (strRoles.includes("ROLE_AGEN") || strRoles.includes("ROLE_CS") || strRoles.includes("ROLE_RSL")){
          show = true
      }

      return show
    },
    searchOnEnter(event){
      if (this.search !== event.target.value) { //Krusial untuk search
        // console.log(`${event.target.value} vs ${this.search}`)
        this.currentPage = 1
        this.search = event.target.value
        this.fetchUsers()
      }
    },
    fetchUsers() {
      UserService.getAllUser(this.currentPage, this.pageSize, "id", "DESC", this.search).then(
          response => {
            // console.log(response.data.items)
            const { items, totalPages} = response.data
            this.users = items
            this.totalPaginationPages = totalPages

            console.log(`TotalPage ${totalPages} and TotalItems ${items} `)

          },
          error => {
            console.log(error.response)
            if (error.response.status===401){
              // this.snackBarMesage = "Anda tidak mempunyai Akses!!"
              // this.snackbar = true
            }
          }
      )

    },
    fetchParent() {
      if (this.currentUser.organizationLevel === EOrganizationLevel.SYS || this.currentUser.organizationLevel === EOrganizationLevel.CORP ) {
        FDivisionService.getAllFDivision().then(
            response => {

              // console.log(JSON.stringify(response.data))

              this.itemsFDivision = response.data
            },
            error => {
              console.log(error.response)
            }
        )
      }else {
        FDivisionService.getFDivisionById(this.currentUser.fdivisionBean).then(
            response=>{
              this.itemsFDivision = [response.data]
              console.log(response.data.items)
            },
            error=>{
              console.log(error.response)
            }
        )
      }

      FSalesmanService.getAllFSalesman().then(
          response => {
            // console.log(response.data)
            this.itemsFSalesman = response.data
          },
          error => {
            console.log(error.response)
          }
      )

    },

    showDialogNew() {
      this.itemSelectedIndex =-1
      const itemModified = Object.assign({}, '')
      // this.formDialogShow = true
      this.formMode = FormMode.NEW_FORM
      this.$refs.refFormDialog.showDialog(this.itemSelectedIndex, itemModified, this.itemsFDivision, this.itemsFSalesman)
    },
    saveDataNew(itemFromRest){
      this.itemSelected = itemFromRest
      this.closeDialog()
      /**
       * Setiap data baru harus terlihat
       */
      // this.users.push(this.itemSelected)
      // this.users = [itemFromRest].concat(this.users)
      // this.users.unshift(itemFromRest)
      this.fetchUsers()
    },

    showDialogEdit (item) {
      this.itemSelectedIndex = this.usersFiltered.indexOf(item)
      const itemModified = Object.assign({}, item)
      // this.formDialogShow = true
      this.formMode = FormMode.EDIT_FORM
      this.$refs.refFormDialog.showDialog(this.itemSelectedIndex, itemModified, this.itemsFDivision, this.itemsFSalesman)
    },
    saveDataEdit(item){
        this.itemSelected = item
        try {
          Object.assign(this.users[this.itemSelectedIndex], this.itemSelected)
        }catch (e) {
          this.fetchUsers()
          e.toString()
        }
        this.closeDialog()
    },


    deleteDialogShow (item) {
      // console.log(item)
      this.itemSelectedIndex = this.usersFiltered.indexOf(item)
      this.itemSelected = Object.assign({}, item)
      // this.myConfirmDelete = true
      this.$refs.refDeleteConfirmDialog.showDialog(this.itemSelectedIndex, item.username)
    },
    deleteDialogMultiShow(){
      if (this.multiSelect===true){
        // console.log(`nilai ${this.selectedItems}`)
        this.$refs.refDeleteConfirmDialog.showDialogMulti(this.selectedItems, `${this.selectedItems.length} items selected`)
      }
    },
    deleteItemConfirmedSingleSelect(index){
      console.log("delete SingleItem: " + index)

      const deletedItem = this.users[this.itemSelectedIndex]
      UserService.deleteUser(deletedItem.id).then(
        () => {
          // console.log("hapus bos " + response.data + " >> " + this.itemSelectedIndex)
          this.users.splice(this.itemSelectedIndex, 1)
          this.closeDialog()
        },
        error => {
          console.log(error)
          this.$refs.refDeleteConfirmDialog.setDialogState(false)
        }
      )
    },
    deleteItemConfirmedMultiSelect(items){
      // console.log(okeMessage)
      if (items.length >-1){
        let itemIds = []
        for (let i=0; i<items.length; i++){
            itemIds.push(items[i].id)
        }
        UserService.deleteAllUser(itemIds).then(
            response => {
              if (response.data) {
                for (let i=0; i<items.length; i++){
                  const index = this.usersFiltered.indexOf(items[i])
                  this.users.splice(index, 1)
                }
                this.closeDialog()
              }
            },
            error => {
              console.log("error " + error)
            }
        )
        // console.log(items)
        // UserService.deleteAllUsers()
      }

    },
    closeDialog () {
      // this.myConfirmDialog = false
      this.formMode = ''
      this.$refs.refDeleteConfirmDialog.setDialogState(false)
      this.$refs.refFormDialog.setDialogState(false)

      this.$nextTick(() => {
        this.itemSelected = Object.assign({}, new User())
        this.itemSelectedIndex = -1
      })
    },
    getColorStatusActive (trueFalse) {
      if (trueFalse === true) return 'green'
      else if (trueFalse === false) return 'gray'
      else return 'gray'
    },
    getColorOrganizationLevel (value) {
      if (value === 'SYS') return 'red'
      else if (value==='CORP') return 'warning'
      else return 'gray'
    },
    lookupFSalesman (fsalesmanBean) {
      const str = this.itemsFSalesman.filter(x => x.id===fsalesmanBean)
      if (str.length>0){
        return str[0]
      }else {
        return '-'
      }
    },
    lookupFDivision (fdivisionBean) {
      const str = this.itemsFDivision.filter(x => x.id===fdivisionBean)
      if (str.length>0){
        return str[0].description
      }else {
        return '-'
      }
    },
    rolesToArray(roles){
      const arrRoles = [];
      if (roles !== undefined){
        roles.forEach(
            role=>{
              // console.log(role.name)
              if (role.name !== undefined & role.name !== null ){
                arrRoles.push(role.name);
              }
            }
        )
      }

      if(arrRoles.length >0){
        return arrRoles
      }else {
        return roles
      }

      // return roles
    }

  },
  mounted() {
    this.fetchUsers()
    this.fetchParent()

  }

}
</script>

<style scoped>

</style>