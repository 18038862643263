export default class FSalesman {
  constructor(
    id,
    spcode,
    spname,
    avatarImage,
    fdivisionBean,
    statusActive=true,

    salesType,

    address1,
    city1,
    state1,
    phone,
    countryCode = 62,
    mobile,
    whatsApp,
    email,
    joinDate,
    lastTrans,
    bornPlace,
    bornDate,
    religion,
    nomorRekening,

    childOf,

    created,
    modified,
    modifiedBy
  ) {
    this.id = id;
    this.spcode = spcode;
    this.spname = spname;
    this.avatarImage = avatarImage;
    this.fdivisionBean = fdivisionBean;
    this.statusActive = statusActive;

    this.salesType = salesType;

    this.address1 = address1;
    this.city1 = city1;
    this.state1 = state1;
    this.phone = phone;
    this.countryCode = countryCode;
    this.mobile = mobile;
    this.whatsApp = whatsApp;
    this.email = email;
    this.joinDate = joinDate;
    this.lastTrans = lastTrans;
    this.bornPlace = bornPlace;
    this.bornDate = bornDate;
    this.religion = religion;

    this.nomorRekening=nomorRekening;

    this.childOf = childOf;

    this.created = created;
    this.modified = modified;
    this.modifiedBy = modifiedBy;
  }

}
