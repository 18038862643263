export default class User {
  constructor(
      id,
      username="", email="", password="", isAccountNonLocked, fdivisionBean, roles, organizationLevel="", salesmanOf,
              phone, countryCode, avatarImage="", birthDate, fullName=""
  ) {
    this.username = username;
    this.email = email;
    this.password = password;

    this.isAccountNonLocked = isAccountNonLocked;
    this.fdivisionBean = fdivisionBean;
    this.roles = roles;
    this.organizationLevel = organizationLevel;
    this.salesmanOf = salesmanOf;

    this.phone = phone;
    this.countryCode = countryCode;
    this.avatarImage = avatarImage;
    this.birthDate = birthDate;

    this.fullName = fullName;
    this.id = id;

  }
}
